import React from 'react';

const Input = props => {
  return (
    <input
      {...props}
      className="w-full bg-transparent font-body text-base leading-tight text-primary focus:outline-none focus-visible:outline-none"
    />
  );
};

export { Input };

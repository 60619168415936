import React, { useContext } from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import Layout from '../../../components/287634/layout/layout';
import Button from '../../../components/287634/button/button';
import { H2, P } from '../../../components/287634/typography';
import { CompanyContext } from '../../../context/company.context';
import { Field } from '../../../components/287634/form/field/field';
import { Input } from '../../../components/287634/form/input/input';
import axios from 'axios';
import { navigateToCustomPage } from '../../../utils/navigate';

const Enter = ({ getToken }: PageProps) => {
  const { getPublicURL } = useContext(CompanyContext);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      code: Yup.number()
        .typeError('El valor ingresado debe ser un número.')
        .required('Ingresa el número que has recibido por email.'),
    }),
    onSubmit: (values: FormikValues) => {
      submit(values.code);
    },
  });

  const submit = (code: string) => {
    axios
      .post(
        `/apiweb/customer/emailcode/validation`,
        {
          code,
        },
        {
          params: {
            token: getToken(),
          },
        }
      )
      .then(response => {
        const { action, url } = response.data;
        if (action === 'redirect') {
          navigateToCustomPage(`/${url}`, { replace: true })?.();
        } else {
          throw new Error('Hubo un problema con su número.');
        }
      })
      .catch(error => {
        console.warn(error);
        if (typeof error === 'object') {
          formik.setErrors({
            code: 'El número ingresado no es correcto.',
          });
        } else {
          formik.setErrors({ code: error });
        }
      });
  };

  return (
    <Layout>
      <section className="mx-5 w-full text-center text-primary">
        <H2 className="mt-[60px] mb-5">Código de seguridad</H2>
        <P className="mb-5">Ingresa el número enviado por email</P>
        <img className="mx-auto mb-5 w-2/3" alt="Easy" src={getPublicURL(`/theme/assets/images/status/easy.svg`)} />
      </section>
      <form
        className="flex w-full flex-auto flex-col items-center justify-between px-5"
        autoComplete="false"
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          formik.handleSubmit();
        }}
        onChange={() => {
          formik.setSubmitting(false);
          formik.setErrors({});
        }}
      >
        <section className="w-full">
          <Field label="Ingresa tu número" warning={formik.errors.code as string}>
            <Input className="font-mono" value={formik.values.code} onChange={formik.handleChange('code')} />
          </Field>
        </section>
        <Button type="submit" disabled={formik.isSubmitting}>
          Enviar
        </Button>
      </form>
    </Layout>
  );
};

export default Enter;
